import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonType,
  EditCard,
  LanguageWithFlag,
  ToggleSwitch
} from 'components'
import { ModalWrapper } from 'components/shared/modal/modal-wrapper'
import { BsTranslate } from 'react-icons/bs'
import { classNames } from 'util/shared'
import { LanguagesTypes } from 'util/enums'
import { FormData } from 'models/request-page'

type LanguagesModalProps = {
  languagesModalOpen: boolean
  setLanguagesModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  formData: FormData
  setFormData: React.Dispatch<React.SetStateAction<FormData>>
}

export function LanguagesModal({
  languagesModalOpen,
  setLanguagesModalOpen,
  formData,
  setFormData
}: LanguagesModalProps) {
  const { t } = useTranslation()

  function handleLanguageDefault(language: string) {
    setFormData(prev => ({
      ...prev,
      languages: {
        ...prev.languages,
        fallback: language
      }
    }))
  }

  function handleToggle(language: string) {
    const newLanguagesToUse = [...formData.languages.languages]

    const index = newLanguagesToUse.indexOf(language)

    if (index === -1) {
      newLanguagesToUse.push(language)
    } else {
      newLanguagesToUse.splice(index, 1)
    }

    setFormData(prev => ({
      ...prev,
      languages: {
        ...prev.languages,
        languages: newLanguagesToUse
      }
    }))
  }

  return (
    <ModalWrapper
      modalOpen={languagesModalOpen}
      setModalOpen={setLanguagesModalOpen}
      headerProps={{
        icon: <BsTranslate className="text-primary" size={22} />,
        title: t('modal.languageConfigs.title'),
        subtitle: t('modal.languageConfigs.subTitle')
      }}
      modalSize="medium"
    >
      <>
        <div className="overflow-y-scroll scroll-1 pr-2 h-[45vh] flex flex-col gap-4">
          {Object.values(LanguagesTypes).map((languageCode, index) => (
            <EditCard className="dark:border-gray-400" key={index}>
              <div className="flex justify-between items-center">
                <div>
                  <LanguageWithFlag languageCode={languageCode} />
                  <p className="text-gray-400">{languageCode}</p>
                </div>
                <div className="flex gap-4">
                  <button
                    onClick={() => handleLanguageDefault(languageCode)}
                    className={classNames(
                      'text-sm rounded-lg',
                      formData.languages?.fallback === languageCode
                        ? 'bg-lightblue text-white-200 dark:text-black px-4'
                        : 'underline text-lightblue'
                    )}
                  >
                    {formData.languages?.fallback === languageCode
                      ? t(`modal.languageConfigs.default`)
                      : t(`modal.languageConfigs.setAsDefault`)}
                  </button>
                  <ToggleSwitch
                    className={classNames(
                      formData.languages?.languages?.includes(languageCode)
                        ? 'bg-primary-500 border-primary-500 '
                        : 'dark:bg-gray-200 dark:border-gray-200'
                    )}
                    state={formData.languages?.languages?.includes(
                      languageCode
                    )}
                    setState={() => handleToggle(languageCode)}
                  />
                </div>
              </div>
            </EditCard>
          ))}
        </div>
        <div className="flex justify-end mt-5">
          <Button
            buttonType={ButtonType.Secondary}
            onClick={() => setLanguagesModalOpen(false)}
            className="dark:bg-black mr-2"
          >
            {t('modal.createDisclaimer.Cancel')}
          </Button>
          <Button onClick={() => setLanguagesModalOpen(false)}>
            {t('modal.createDisclaimer.Save')}
          </Button>
        </div>
      </>
    </ModalWrapper>
  )
}
