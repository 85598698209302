import { useTranslation } from 'next-i18next'
import ReactCountryFlag from 'react-country-flag'

export const LanguageFlags = {
  en: <ReactCountryFlag countryCode="US" svg />,
  pt: <ReactCountryFlag countryCode="BR" svg />,
  'en-GB': <ReactCountryFlag countryCode="GB" svg />,
  'pt-PT': <ReactCountryFlag countryCode="PT" svg />,
  bg: <ReactCountryFlag countryCode="BG" svg />,
  hr: <ReactCountryFlag countryCode="HR" svg />,
  cs: <ReactCountryFlag countryCode="CZ" svg />,
  da: <ReactCountryFlag countryCode="DK" svg />,
  nl: <ReactCountryFlag countryCode="NL" svg />,
  et: <ReactCountryFlag countryCode="EE" svg />,
  fi: <ReactCountryFlag countryCode="FI" svg />,
  fr: <ReactCountryFlag countryCode="FR" svg />,
  de: <ReactCountryFlag countryCode="DE" svg />,
  el: <ReactCountryFlag countryCode="GR" svg />,
  hu: <ReactCountryFlag countryCode="HU" svg />,
  it: <ReactCountryFlag countryCode="IT" svg />,
  ga: <ReactCountryFlag countryCode="IE" svg />,
  lv: <ReactCountryFlag countryCode="LV" svg />,
  lt: <ReactCountryFlag countryCode="LT" svg />,
  mt: <ReactCountryFlag countryCode="MT" svg />,
  pl: <ReactCountryFlag countryCode="PL" svg />,
  ro: <ReactCountryFlag countryCode="RO" svg />,
  sk: <ReactCountryFlag countryCode="SK" svg />,
  es: <ReactCountryFlag countryCode="ES" svg />,
  'es-LA': <ReactCountryFlag countryCode="MX" svg />,
  sv: <ReactCountryFlag countryCode="SE" svg />,
  hi: <ReactCountryFlag countryCode="IN" svg />,
  zh: <ReactCountryFlag countryCode="CN" svg />,
  'zh-TW': <ReactCountryFlag countryCode="TW" svg />,
  ja: <ReactCountryFlag countryCode="JP" svg />,
  ar: <ReactCountryFlag countryCode="SA" svg />
}

interface Props {
  languageCode: string
  onlyFlag?: boolean
}

export function LanguageWithFlag({ languageCode, onlyFlag = false }: Props) {
  const { t } = useTranslation()

  return (
    <span className="flex items-center gap-1">
      {LanguageFlags[languageCode as keyof typeof LanguageFlags]}
      {!onlyFlag && t(`languages.${languageCode}`)}{' '}
    </span>
  )
}
