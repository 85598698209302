import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { classNames } from 'util/shared'

interface DropdownProps {
  items: Array<{ onClick: () => void; label: string | JSX.Element }>
  bottomItems?: Array<{ onClick: () => void; label: string }>
  title?: string
  subtitle?: string
  children: React.ReactNode
  classes?: string
  position?: 'left' | 'bottomLeft' | 'bottomRight' | 'right'
}

export function Dropdown({
  title,
  subtitle,
  items,
  bottomItems,
  children,
  classes,
  position = 'bottomLeft'
}: DropdownProps) {
  const positionClasses = {
    left: 'right-[calc(100%+8px)] top-0 ',
    bottomRight: 'right-0 top-[calc(100%+8px)]',
    bottomLeft: 'left-0 top-[calc(100%+8px)]',
    right: 'left-[calc(100%+8px)] top-0 '
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button className="relative flex items-center text-gray-600 dark:text-white">
            {children}
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className={classNames(
                'origin-top-right absolute z-[2] cursor-pointer  rounded-md shadow-lg bg-white dark:bg-gray ring-1 ring-black ring-opacity-5 div focus:outline-none pointer',
                positionClasses[position],
                classes
              )}
            >
              {(title || subtitle) && (
                <div className="px-4 py-3 border-b border-gray-100">
                  <p className="text-sm dark:text-white font-bold">{title}</p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                    {subtitle}
                  </p>
                </div>
              )}
              {items?.map(({ onClick, label }, index: number) => {
                return (
                  <Menu.Button
                    key={index}
                    onClick={onClick}
                    className={classNames(
                      'dark:hover:text-gray-200 hover:text-gray-200 text-gray dark:text-white px-4 py-2 cursor-pointer block w-full text-left whitespace-nowrap',
                      bottomItems
                        ? 'border-none'
                        : 'border-b last:border-none border-gray-100'
                    )}
                  >
                    {label}
                  </Menu.Button>
                )
              })}

              {bottomItems && (
                <div className="border-b border-gray-50">
                  {bottomItems?.map(({ onClick, label }, index: number) => {
                    return (
                      <Menu.Button
                        key={index}
                        onClick={onClick}
                        className="hover:text-gray-200 !border-none text-gray dark:text-white px-4 py-2 cursor-pointer block w-full text-left"
                      >
                        {label}
                      </Menu.Button>
                    )
                  })}
                </div>
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
