import { LegislationsTypes } from 'util/enums'
import { Select } from '../select/select'
import { useTranslation } from 'next-i18next'
import { H3 } from '../typography'

interface LegislationSelectProps {
  legislation: string
  setLegislation: React.Dispatch<React.SetStateAction<string>>
  containerClasses?: string
}

export function LegislationSelect({
  legislation,
  setLegislation,
  containerClasses
}: LegislationSelectProps) {
  const { t } = useTranslation()

  const legislationsMap: Record<string, LegislationsTypes[]> = {
    southAmerica: [LegislationsTypes.LGPD],
    northAmerica: [
      LegislationsTypes.PIPEDA,
      LegislationsTypes.LAW_25,
      LegislationsTypes.CCPA
    ],
    europe: [
      LegislationsTypes.GDPR,
      LegislationsTypes.CNIL,
      LegislationsTypes.NFADP,
      LegislationsTypes.UK_GDPR
    ],
    asia: [
      LegislationsTypes.DPDPA,
      LegislationsTypes.PDPL,
      LegislationsTypes.APPI,
      LegislationsTypes.PDPA_MALASIA,
      LegislationsTypes.PDPA_THAILANDIA,
      LegislationsTypes.PIPL
    ],

    africa: [LegislationsTypes.POPIA],
    oceania: [LegislationsTypes.APA]
  }

  return (
    <div>
      <H3 fontWeight="bold" className="mb-1">
        {t('disclaimer.style.Text.legislation')}
      </H3>
      <Select
        containerClasses={containerClasses}
        hasSections
        params={Object.entries(legislationsMap).reduce(
          (
            acc: { name: string; value: undefined | string }[],
            [region, legislations]
          ) => {
            acc.push({
              name: t(`disclaimer.style.Text.regions.${region}`),
              value: undefined
            })

            legislations.forEach(legislation => {
              acc.push({
                name: t(`disclaimer.style.Text.legislations.${legislation}`),
                value: legislation
              })
            })

            return acc
          },
          []
        )}
        value={legislation}
        onChange={e => {
          setLegislation(e.value as any)
        }}
      />
    </div>
  )
}
